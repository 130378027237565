import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PageData } from "@modules/shared/models/page.model";
import { environment } from "@environment/environment";
import { map } from "rxjs/operators";
import { ExpensesMapperService } from "@api/expenses/services/expenses-mapper.service";
import { ExpenseData } from "@api/expenses/models/expenses.model";
import { CreateExpenseData } from "@api/expenses/models/create-expense.model";
import { DatePipe } from "@angular/common";
import { CurrencyData } from "@api/expenses/models/currency.model";
import { CostCategoryData } from "@api/expenses/models/cost-category.model";
import { DefaultResponseData } from "@api/http";
import { ExpensePayerTypeData } from "../models/expense-payer.model";

const httpOptions = {
  headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable()
export class ExpensesService {
  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private readonly expensesMapperService: ExpensesMapperService
  ) {}

  // Get expenses list
  public list(caseId: string, params = {}): Observable<PageData<ExpenseData>> {
    return this.http
      .get<any>(
        environment.gateway_endpoint + `cases/` + caseId + "/expenses",
        {
          params: params,
        }
      )
      .pipe(
        map((response) => {
          const { items, ...pageData } = response.result;
          return {
            ...pageData,
            items: this.expensesMapperService.mapMany(items),
          } as PageData<ExpenseData>;
        })
      );
  }

  // Get Total Cost
  public getTotalCost(caseId, params = {}): Observable<any> {
    return this.http.get<any>(
      environment.gateway_endpoint + "cases/" + caseId + "/expenses/total-cost",
      { params: params }
    );
  }

  // Get covered by list
  public getExpensePayers(): Observable<ExpensePayerTypeData[]> {
    return this.http
      .get<DefaultResponseData<ExpensePayerTypeData[]>>(
        environment.gateway_endpoint + `cases/expenses/expense-covers`
      )
      .pipe(map((response) => response.result));
  }

  // Get Cost Category
  public getCostCategory(): Observable<CostCategoryData[]> {
    return this.http
      .get<DefaultResponseData<CostCategoryData[]>>(
        environment.gateway_endpoint + "cases/expenses/cost-categories"
      )
      .pipe(map((response) => response.result));
  }

  // Get currency list
  public getCurrencyList(): Observable<CurrencyData[]> {
    return this.http.get<CurrencyData[]>(
      environment.gateway_endpoint + "entities/currencies"
    );
  }

  // Archive expense
  public archiveExpense(
    caseId: string,
    expenseId: string
  ): Observable<ExpenseData> {
    return this.http
      .delete<ExpenseData>(
        environment.gateway_endpoint +
          `cases/` +
          caseId +
          "/archive/expenses/" +
          expenseId,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Delete expense
  public deleteExpense(
    caseId: string,
    expenseId: string
  ): Observable<DocumentType> {
    return this.http
      .delete<DocumentType>(
        environment.gateway_endpoint +
          `cases/` +
          caseId +
          "/expenses/" +
          expenseId,
        httpOptions
      )
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Download expense
  public downloadExpense(
    entityType: string,
    entityId: string,
    docId: string,
    expenseId: string
  ): Observable<{ result: string }> {
    return this.http.get<{ result: string }>(
      environment.gateway_endpoint +
        `documents/` +
        entityType +
        "/" +
        entityId +
        "/download/file",
      {
        params: {
          document_id: docId,
          expense_id: expenseId,
        },
      }
    );
  }

  // Add expense
  public create(
    entityType,
    entityId,
    data: CreateExpenseData
  ): Observable<ExpenseData> {
    const formData = this.prepareExpenseDataStructure(data);

    return this.http
      .post(
        environment.gateway_endpoint +
          entityType +
          "/" +
          entityId +
          "/expenses",
        formData
      )
      .pipe(
        map((response: any) =>
          this.expensesMapperService.mapOne(response.result.document)
        )
      );
  }

  // Edit expense
  public editExpense(
    entityType,
    entityId,
    expenseId,
    data: CreateExpenseData
  ): Observable<ExpenseData> {
    const formData = this.prepareExpenseDataStructure(data);

    return this.http
      .post(
        environment.gateway_endpoint +
          entityType +
          "/" +
          entityId +
          "/expenses/" +
          expenseId,
        formData
      )
      .pipe(
        map((response: any) =>
          this.expensesMapperService.mapOne(response.result.document)
        )
      );
  }

  private prepareExpenseDataStructure(data: CreateExpenseData): FormData {
    data = this.expensesMapperService.prepareCreate(data);
    const formData: FormData = new FormData();

    if (data.task_id) {
      formData.append("task_id", data.task_id.toString());
    }

    if (data.file && data.file[0] instanceof File && data.file.length > 0) {
      formData.append("file", data.file[0]);
    }
    if (data.title) {
      formData.append("title", data.title);
    }
    if (data.name) {
      formData.append("name", data.name);
    }
    if (!Number.isNaN(data.amount)) {
      formData.append("amount", data.amount.toString());
    }
    if (data.cost_category_id) {
      formData.append("cost_category_id", data.cost_category_id.toString());
    }
    if (data.expat_case_id) {
      formData.append("expat_case_id", data.expat_case_id.toString());
    }
    if (data.covered_by) {
      formData.append("covered_by_id", data.covered_by.toString());
    }
    if (data.currency_code) {
      formData.append("currency_code", data.currency_code);
    }
    if (data.incurred_at) {
      formData.append(
        "incurred_at",
        this.datePipe.transform(data.incurred_at, "yyyy-MM-dd")
      );
    }

    return formData;
  }
}
